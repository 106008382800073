<template>
  <page-container class="goods-detail-container">
    <van-icon class="back-icon" name="arrow-left" @click.stop="$router.back()"/>

    <!-- 商品图片 -->
    <div class="goods-img-swiper-wrap">
      <div class="swiper-container">
        <van-swipe
            style="height: 100%"
            @change="index => this.imgCurrent = ++index"
        >
          <van-swipe-item
              v-for="({ url }, index) of imageList"
              :key="index"
              @click="ImagePreview({
                images: imageList.map(({ url }) => url),
                startPosition: index,
              })"
          >
            <van-image
                :src="url"
                fill="cover"
            />
          </van-swipe-item>
          <template #indicator>
            <div class="custom-indicator">
              {{ imgCurrent }} / {{ imageList.length }}
            </div>
          </template>
        </van-swipe>
      </div>
    </div>

    <div class="integral-goods-container">
      <div class="goods-info-card">
        <div class="priceCard">
          <div class="goods-price-container">
            <div v-if="goodsInfo.goodsPrice">
              <span class="display-price"><span>{{ goodsInfo.goodsPrice }}</span></span>
            </div>

            <div v-if="goodsInfo.integralUsed">
              <span class="display-integral"><span>{{ goodsInfo.integralUsed }}</span></span>
            </div>
          </div>
          <div class="goods-originial-price" v-if="goodsInfo.primePrice">
            <span class="originial-price">原价:</span>
            <span class="originialLogo">￥</span>
            <span class="goodsPrice">{{ goodsInfo.primePrice }}</span>
          </div>
        </div>
      
        <div class="goods-name-container">
          <p>{{ goodsInfo.name }}</p>
          <div>{{ goodsInfo.subName }}</div>
        </div>
      </div>

      <div class="goods-info-card">
        <!-- 是否包邮 -->
        <div
            class="postage-desc"
            :class="{'has-postage': +goodsInfo.attribute === 1}"
        >
          <van-icon
              :name="+goodsInfo.attribute !== 1 ? 'passed' : 'close'"
          />
          {{ postageDesc }}
        </div>

        <div class="stock-info-container">
          <div>
            下单2~4天可发货
          </div>
          <div v-if="goodsInfo.goodsStock">
            库存：{{ goodsInfo.goodsStock.count }}
          </div>
        </div>
      </div>

      <div ref="goodsDetail" class="goods-detail-container" v-html="goodsInfo.goodsDetail"/>
    </div>

    <div class="goods-detail-bottom-bar">
      <div class="other-service-btn-container">
        <!--        <div @click="() => $router.push('/customerService')">-->
        <div @click="() => $router.push('/afterSale')">
          <van-icon name="service-o"/>
          售后
        </div>
      </div>
      <div class="buy-container">
        <van-button
            round
            type="primary"
            style="width: 100%"
            @click="handleToByBtnClick"
        >
          立刻兑换
        </van-button>
      </div>
    </div>

  </page-container>
</template>

<script>
import { getIntegralGoodsInfo } from '@/api/integralGoods';
import { getUserInfo } from '@/api/user';
import { ImagePreview } from 'vant';

export default {
  name: 'integralGoodsDetail',
  data() {
    return {
      integralGoodsId: '',
      imgCurrent: 1,
      userInfo: {},
      goodsInfo: {},
    };
  },
  computed: {
    // 商品图片
    imageList() {
      let { imageList, thumbImageUrl } = this.goodsInfo || {};
      let image = Array.isArray(imageList) ? imageList : [];
      let first = thumbImageUrl ? [{ url: thumbImageUrl }] : [];
      return [...first, ...image];
    },
    postageDesc() {
      let { goodsInfo } = this;
      return {
        '1': '不包邮',
        '2': '包邮',
        '3': '部分地区包邮',
      }[goodsInfo.attribute] || '';
    },
  },
  created() {
    let { integralGoodsId } = this.$route.query;
    this.integralGoodsId = integralGoodsId;
    getIntegralGoodsInfo({ integralGoodsId }).then(res => {
      this.goodsInfo = res || {};
      // 商品详情富文本图片预览
      this.$nextTick(() => {
        let $goodsDetailContainer = this.$refs.goodsDetail;
        let $images = $goodsDetailContainer.querySelectorAll('img');
        let images = [...$images].map(e => e.src);
        $images.forEach(($img, index) => {
          $img.addEventListener('click', () => {
            ImagePreview({
              images,
              startPosition: index,
            });
          });
        });
      });
    });

    getUserInfo().then(res => {
      this.userInfo = res || {};
    });
  },
  methods: {
    ImagePreview,
    handleToByBtnClick() {
      this.$store.commit('integralPayment/setRefreshing', true);
      let { integralGoodsId } = this;
      this.$router.push({
        path: '/integralPayment',
        query: { integralGoodsId },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.back-icon {
  position: fixed;
  z-index: 2;
  top: 12px;
  left: 12px;
  box-sizing: border-box;
  padding: 6px;
  font-size: 24px;
  color: #000;
  background-color: rgba(255, 255, 255, .8);
  border-radius: 100%;
  border: 1px solid #dedede;
}

.goods-detail-container {
  padding: 0 0 54px 0;

  .goods-img-swiper-wrap {
    position: relative;
    width: 100%;
    padding-top: 100%;

    .swiper-container {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .custom-indicator {
        position: absolute;
        z-index: 20;
        right: 0;
        bottom: 15px;
        height: 20px;
        padding: 0 10px;
        background: #00000080;
        border-radius: 10px 0 0 10px;
        font-size: 10px;
        color: #fff;
        line-height: 20px;
      }

      .van-swipe-item {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .integral-goods-container {

    .goods-info-card {
      margin: 10px;
      padding: 10px;
      border-radius: 15px;
      background-color: #fff;
    }
    .priceCard{
      display: flex;
      justify-content: space-between;
      
    }
    .goods-originial-price{
        display: flex;
        align-items: flex-end;
        height: 24px;
        // font-weight: bolder;
        color: #e93b3d;
        & > span {
          height: 24px;
          line-height: 24px;
          font-size: 20px;
        }
        .originial-price{
          font-size: 20px;
          font-weight: bolder;
        }
        .originialLogo{
          margin: 0 2px 3px 0;
          height: 12px;
          line-height: 12px;
          font-size: 12px;
        }
        .goodsPrice{
          font-weight: bolder;
        }
      }
    .goods-price-container {
      font-size: 0;

      & > div {
        display: inline-block;
        color: #999;
        overflow: hidden;

        & + div {

          & > span:before {
            content: '+';
            margin: 0 4px;
            height: 24px;
            line-height: 24px;
            font-size: 16px;
            color: #999;
            font-weight: normal;
          }
        }
      }

      .display-price {
        display: flex;
        align-items: flex-end;
        height: 24px;
        font-weight: bolder;
        color: #e93b3d;

        & > span {
          height: 24px;
          line-height: 24px;
          font-size: 24px;
        }

        &:before {
          content: '￥';
          margin: 0 2px 3px 0;
          height: 12px;
          line-height: 12px;
          font-size: 12px;
          font-weight: normal;
        }
      }

      .display-integral {
        display: flex;
        align-items: flex-end;
        height: 24px;
        font-weight: bolder;
        color: #ffa800;

        & > span {
          height: 24px;
          line-height: 24px;
          font-size: 24px;
        }

        &:after {
          content: '积分';
          margin: 0 2px 3px 0;
          height: 12px;
          line-height: 12px;
          font-size: 12px;
          font-weight: normal;
        }
      }
     
    }

    .goods-name-container {
      margin-top: 8px;
      font-size: 16px;

      & > div {
        margin-top: 6px;
        font-size: 14px;
        color: #666;
      }
    }

    .postage-desc {
      font-size: 14px;
      color: @primary-color;

      &.has-postage {
        color: red;
      }

      .van-icon {
        font-size: 14px;
        font-weight: bolder;

        &.van-icon-passed {

        }
      }
    }

    .stock-info-container {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
      font-size: 12px;
      color: #666;
    }

    /deep/ .goods-detail-container {
      padding: 10px 0;
      background-color: #fff;

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
  }

  .goods-detail-bottom-bar {
    position: fixed;
    z-index: 2;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    width: 100%;
    height: 54px;
    background-color: #fff;

    .other-service-btn-container {
      display: flex;
      height: 100%;

      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 47px;
        color: #666;

        & > .van-icon {
          font-size: 21px;
        }
      }
    }

    .buy-container {
      width: 120px;
      font-size: 14px;
      font-weight: bolder;
    }
  }
}
</style>
